export const GET_INFO = 'GET_INFO'
export const GET_REPOS = 'GET_REPOS'
export const GET_FOLLOWING = 'GET_FOLLOWING'
export const GET_STARRED = 'GET_STARRED'
export const GET_CONTRIBUTIONS = 'GET_CONTRIBUTIONS'
export const INFO_LOADED = 'INFO_LOADED'
export const REPOS_LOADED = 'REPOS_LOADED'
export const FOLLOWING_LOADED = 'FOLLOWING_LOADED'
export const STARRED_LOADED = 'STARRED_LOADED'
export const CONTRIBUTIONS_LOADED = 'CONTRIBUTIONS_LOADED'
export const API_ERROR = 'API_ERROR'
export const ALREADY_LOADED = 'ALREADY_LOADED'
export const RESYNC_DATA = 'RESYNC_DATA'
export const DATA_SYNCED = 'DATA_SYNCED'
